
























import { Component, Vue } from 'vue-property-decorator'
import { Admin } from '@/api'

@Component({
  components: {
    LogsTable: () => import('@/components/Logs/LogsTable.vue')
  }
})
export default class UserCouponsLogs extends Vue {
  data = []
  fields = [
    { key: 'accountId' },
    { key: 'createdAt' },
    { key: 'sourceAccount' },
    { key: 'content', label: 'Note' },
  ]

  async mounted() {
    const { result } = await Admin.getAccountNotes()

    this.data = result.reverse().map(e => {
      return {
        ...e,
        createdAt: new Date(e.createdAt).toUTCString()
      }
    })
  }
}
